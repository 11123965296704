// web日志管理
<template>
    <div class="jobs">
        <div class="screening">
            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="筛选框" name="1">
                    <div class="screening-out">
                        <el-form :inline="true" :model="searchFrom" class="demo-form-inline">
                            <el-row>
                                <el-col :span="22">
                                    <el-form-item label="日志名称">
                                        <el-input v-model="searchFrom.keyword" placeholder="请输入需要查询的日志名称" size="mini"></el-input>
                                    </el-form-item>
                                    <!-- <el-form-item label="所属部门">
                                        <el-input v-model="searchFrom.name" placeholder="请输入需要查询的部门名称"></el-input>
                                    </el-form-item> -->
                                    <el-form-item label="访问时间">
                                        <el-date-picker
                                            v-model="valueTime"
                                            type="datetimerange"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            clearable
                                            value-format="YYYY-MM-DD HH:mm:ss"
                                            size="mini"
                                            :default-time="defaultTime">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2">
                                    <!-- <el-form-item> -->
                                        <div class="title-buttom">
                                            <el-button class="cancel" size="mini" @click="reset" round>重置</el-button>
                                            <el-button type="primary" size="mini" class="confirmAdd" @click="getDataList(true)" round>查询</el-button>
                                        </div>
                                    <!-- </el-form-item> -->
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="title-name">
                    日志列表
                </div>
            </el-col>
            <el-col :span="12">
                <div class="title-buttom">
                    <el-tooltip content="刷新列表" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-refresh-right" @click="reset" circle></el-button>
                    </el-tooltip>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div>
                    <el-table
                    :data="tableData"
                    tooltip-effect="dark"
                    :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
                    :max-height="tableHeight"
                    v-loading="loading"
                    style="width: 100%">
                        <el-table-column type="expand">
                            <template #default="props">
                                <el-form label-position="left" inline class="demo-table-expand">
                                    <el-form-item label="用户名">
                                        <span>{{ props.row.username }}</span>
                                    </el-form-item>
                                    <el-form-item label="ip">
                                        <span>{{ props.row.ip }}</span>
                                    </el-form-item>
                                    <el-form-item label="业务类型">
                                        <span>{{ props.row.businessType }}</span>
                                    </el-form-item>
                                    <el-form-item label="类">
                                        <span>{{ props.row.className }}</span>
                                    </el-form-item>
                                    <el-form-item label="方法">
                                        <span>{{ props.row.methodName }}</span>
                                    </el-form-item>
                                    <el-form-item label="访问时间">
                                        <span>{{ props.row.createTime }}</span>
                                    </el-form-item>
                                    <el-form-item label="地点">
                                        <span>{{ props.row.location }}</span>
                                    </el-form-item>
                                    <el-form-item label="耗时">
                                        <span>{{ props.row.runTime+'ms' }}</span>
                                    </el-form-item>
                                    <el-form-item label="访问状态">
                                        <span>{{ props.row.statusText }}</span>
                                    </el-form-item>
                                    <el-form-item label="模块名称">
                                        <span>{{ props.row.title }}</span>
                                    </el-form-item>
                                </el-form>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="模块"
                        prop="title">
                        </el-table-column>
                        <el-table-column
                        label="用户名"
                        prop="username">
                        </el-table-column>
                        <el-table-column
                        label="ip"
                        prop="ip">
                        </el-table-column>
                        <el-table-column
                        label="业务类型"
                        prop="businessType">
                        </el-table-column>
                        <el-table-column
                        label="访问时间"
                        prop="createTime">
                        </el-table-column>
                        <el-table-column
                        label="耗时/ms"
                        prop="runTime">
                        </el-table-column>
                        <el-table-column
                        label="访问状态"
                        prop="statusText">
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <div class="pager">
            <div>
               <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                background
                :page-sizes="[10, 20, 30, 40]"
                :page-size="searchFrom.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import webLogs from '@/api/web/webLogs'
import { IconFont } from "@/utils/iconfont"
export default {
    components: {
        IconFont,
    },
    data () {
        return {
            tableHeight:'',
            searchFrom:{
                page:1,
                pageSize:10,
                keyword:'',
                status:'',
                createTimeBegin:'',
                createTimeEnd:'',
            },//搜索值
            currentPage: 1,//分页
            total:0,
            multipleSelection: [],//选中集合
            treeList:[],//部门列表
            value:'',//所属部门
            valueTime:'',//选中时间段
            defaultTime: [
                new Date(2000, 1, 1, 12, 0, 0),
                new Date(2000, 2, 1, 8, 0, 0)
            ], //默认时间 '12:00:00', '08:00:00'
            activeNames: [],//折叠面板
            tableData: [],//数据列表
            loading: true,// loding 状态启用
            
        }
    },
    created() {
        this.tableHeight = this.$store.state.tableHeight
    },
    watch: {

    },
    mounted() {
        this.getDataList(true)
    },
    methods: {
        // 重置
        reset() {
            this.valueTime = []
            this.searchFrom = {
                page: this.currentPage,
                pageSize: 10, 
            }
            this.getDataList(true)
        },
        // 获取岗位列表
        getDataList(type) {
            this.loading = type
            this.searchFrom.createTimeBegin = this.valueTime[0]
            this.searchFrom.createTimeEnd = this.valueTime[1]
            webLogs.getWebLogs({
                ...this.searchFrom
            }).then((res) => {
                this.tableData = res.data.records
                this.total = res.data.total
                this.loading = false
            })
        },
        // 分页
        handleSizeChange(val) {
            this.searchFrom.pageSize = val
            this.getDataList(true)
        },
        handleCurrentChange(val) {
            this.searchFrom.page = val
            this.currentPage = val
            this.getDataList(true)
        },
        // 折叠面板
        handleChange(val) {
            console.log(val);
        },
        // 多选
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
    }
}
</script>

<style lang="scss" scoped>
.pager {
    display: flex;
    justify-content: flex-end;
}
.jobs{
    height: 100%;
    overflow-y: overlay;
    padding-right: 18px;
    .title-name{
        font-size: .9375rem;
        font-weight: 600;
        color: #303133;
    }
    .top-first{
        .search-box{
            width: 18.75rem;
        }
    }
}
.screening{
    margin-bottom: .9375rem;
}
.el-row {
    margin-bottom: .9375rem;
    &:last-child {
        margin-bottom: 0;
    }
}
.space-tile{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.center-body{
    margin: 0 1.875rem;
}
.deletetable{
    color: #FF845B;
    background: #FFE4DB;
}
.deletetable-other{
    margin-right: 1.25rem;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.screening-out{
    margin: 0 .9375rem;
}
</style>

<style lang="scss">
.jobs .el-collapse-item__content {
    padding-bottom: 0 !important;
}
// .jobs .el-button {
//     // border-color: #FFFFFF !important;
// }
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 5.625rem;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>